import React from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import DoneIcon from '@material-ui/icons/Done'
import {
    ShowButton, FunctionField, Pagination, translate, Tab, TabbedShowLayout, CardActions,
    Show, SimpleShowLayout, List, Datagrid, TextField, NumberField, ReferenceField, DateField, EditButton,
    Edit, SimpleForm, TextInput
} from 'react-admin';
import { Actions } from '../common/actions'
import { IntegrationFilters } from '../common/filters'
import FullWidthReferenceManyField from '../common/full-width-reference-many-field'
import TranslatedEnumField from '../common/translated-enum-field'
import TextFileViewer from '../common/text-file-viewer'
import { EntityDataLinkButton } from '../common/buttons/EntityDataLinkButtonView';
import { ReturnEntityToSourceButton } from '../common/buttons/ReturnEntityToSourceButtonView';
import { CancelRunButton } from '../common/buttons/CancelRunButtonView';
import { StartRunButton } from '../common/buttons/StartRunButtonView';
import { CopyToClipboardButtons } from '../common/buttons/copy-to-clipboard-button';
import { SetEntityStatusToSucceededButton } from '../common/buttons/SetEntityStatusToSucceededButtonView';

export const ToggleRunButton = ({ ...props }) => {
    const record = props.record || props.data
    return record.latestRunIsPastEntrypoint
    ? <StartRunButton record={record} {...props} />
    : <CancelRunButton record={record} {...props} />
};

const StatusField = translate(({ translate, source, record = {} }) => {
    const statusTypeId = record[source];
    return (
        <span title={translate(`enums.statusTypes.${record[source]}`)}>
            {
                statusTypeId >= 100 && statusTypeId < 200 ? <ErrorIcon style={{ color: 'red' }} />
                    : (statusTypeId == 200 ? <DoneIcon style={{ color: 'green' }} />
                        : (statusTypeId == 201 ? <DoneIcon style={{ color: 'orange' }} />
                            : (statusTypeId == 202 ? <DoneIcon style={{ color: '#04201b' }} />
                                : (statusTypeId == 0 ? <HourglassEmptyIcon />
                                    : <AutorenewIcon />
                                )
                            )
                        )
                    )

            }</span>
    )
});

export const TaskShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
        </SimpleShowLayout>
    </Show>
);

export const LogShow = props => (
    <Show title={<div />} {...props}>
        <SimpleShowLayout>
            <TextField source="exception" />
            <TextField source="logEvent" />
        </SimpleShowLayout>
    </Show>
);

export const EntityDataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <CopyToClipboardButtons source="stringFileContent" />
            <TextFileViewer source="stringFileContent" contentTypeSource="contentType" />
        </SimpleShowLayout>
    </Show>
);

export const RunDataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextFileViewer source="stringFileContent" contentTypeSource="contentType" />
        </SimpleShowLayout>
    </Show>
);

const ObjectEditForm = (props) => (
    <SimpleForm {...props}>
        <TextField source="name" />
        <ReferenceField reference="integrations" source="integrationId" linkType={false}>
                <TextField source="name" />
        </ReferenceField>
        <TextInput source="comment" />
    </SimpleForm>
);

const TaskDatagrid = props => (
    <Datagrid {...props}>
        <TextField source="id" />
        <StatusField source="statusTypeId" label="Status" />
        <ReferenceField linkType={false} label="Step" reference="moduleconfigurations" source="integrationModuleConfigurationId">
            <TextField source="name" />
        </ReferenceField>
        <TextField source="message" />
        <FunctionField label="# tries" render={record => record && record.statusTypeIdList && record.statusTypeIdList.length} />
        <DateField showTime={true} source="createdAt" />
        <DateField showTime={true} source="updatedAt" />
    </Datagrid>
);

const LogDataGrid = props => (
    <Datagrid {...props} rowClick="expand" expand={<LogShow />}>
        <DateField showTime label="Time" source="timeStamp" />
        <TextField label="Level" source="level" />
        <TextField label="Message" source="message" />
    </Datagrid>
)

export const EntityShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="tasks">
                <FullWidthReferenceManyField
                    label={null}
                    reference="tasks"
                    target="integrationEntityId"
                    sort={{ field: 'updatedAt', order: 'desc' }}>
                    <TaskDatagrid />
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="data" path="data">
                <FullWidthReferenceManyField
                    label={null}
                    reference="entitydatas"
                    target="integrationEntityId">
                    <Datagrid>
                        <ShowButton />
                        <TextField label="Id" source="id" />
                        <TranslatedEnumField type="dataTypes" source="dataTypeId" />
                        <EntityDataLinkButton />
                    </Datagrid>
                </FullWidthReferenceManyField>
            </Tab>
            <Tab label="metadata" path="metadata">
                <TextFileViewer source="metadata" contentType="application/json" />
            </Tab>
            <Tab label="log" path="log">
                <FullWidthReferenceManyField
                    label={null}
                    reference="logs"
                    target="integrationEntityId"
                    sort={{ field: 'timeStamp', order: 'desc' }}
                    perPage={10}
                    pagination={<Pagination />}>
                    <LogDataGrid />
                </FullWidthReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

const ObjectShowActions = () => (
    <CardActions>
    </CardActions>
);

export const ObjectShow = props => (
    <Show actions={<ObjectShowActions />} {...props}>
        <SimpleShowLayout>
            <FullWidthReferenceManyField
                label="Entities"
                reference="entities"
                target="objectId"
                sort={{ field: 'updatedAt', order: 'desc' }}
                perPage={10}
                pagination={<Pagination />}>
                <Datagrid>
                    <ShowButton />
                    <TextField label="Id" source="id" />
                    <StatusField label="Status" source="statusTypeId" />
                    <TranslatedEnumField label="Type" type="entityTypes" source="entityType" />
                    <TextField label="Tracking Id" source="trackingId" />
                    <TextField source="name" />
                    <DateField showTime={true} source="createdAt" />
                    <DateField showTime={true} source="updatedAt" />
                    <ReturnEntityToSourceButton />
                    <SetEntityStatusToSucceededButton />
                </Datagrid>
            </FullWidthReferenceManyField>
        </SimpleShowLayout>
    </Show>
);


export const ObjectEdit = props => (
    <Edit title="Edit object" {...props}>
        <ObjectEditForm />
    </Edit>
);

export const ObjectList = props => (
    <List actions={<Actions />} sort={{ field: 'updatedAt', order: 'desc' }} {...props} filters={<IntegrationFilters showEntityTypeId={true} showIncomplete={true} showUpdatedAtGte={true} showEndpoint={true} showIntegration={true} showHasEntities={false} showModuleConfiguration={false} showStatusTypeId={false} showName={true} />} title="Objects" exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <ShowButton />
            <StatusField label="Status" source="statusTypeId" />
            <ReferenceField reference="integrations" source="integrationId" linkType={false}>
                <TextField source="name" />
            </ReferenceField>
            <TranslatedEnumField label="Type" type="entityTypes" source="entityType" />
            <TextField label="Tracking Id" source="trackingId" />
            <TextField source="name" />
            <ReferenceField label="Company" reference="companies" source="companyId" linkType={false}>
                <TextField source="companyName" />
            </ReferenceField>
            <NumberField source="externalCompanyNumber" />
            <ReferenceField reference="endpoints" source="endpointId" linkType={false}>
                <TextField source="endpointName" />
            </ReferenceField>
            {/*<TextField source="entityCount" label="# entities" />*/}
            <DateField showTime={true} source="createdAt" />
            <DateField showTime={true} source="updatedAt" />
            <TextField source="comment" />
            <EditButton />
        </Datagrid>
    </List>
);